<template>
    <div>
        <div class="creator-program-container">
            <h3>Creator Program</h3>
            <p>
                As a Creator on Memo'd, you'll be part of a knowledge-sharing platform that promotes your writing to a global audience, helps establish you as a thought leader in your field, and helps you monetize your work.
            </p>
            <button 
                :class="['btn btn-primary']"
                :disabled="creatorInvitation === PAYMENT_STATES.PENDING"
                @click.prevent="$modal.show('creator-program')"
            >
                {{ creatorInvitation === PAYMENT_STATES.INVITATION ? "Creator Program Invitation ✉️✨" : "Creator Program Pending" }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { PAYMENT_STATES } from "@/utils/constants";

export default {
    name: "CreatorProgramSetting",
    data: () => ({
        PAYMENT_STATES,
        stripe_state: 0
    }),
    computed: {
        ...mapState({
            creatorInvitation: state => state.User.data.creator_invitation
        })
    },
    async mounted() {
        const { data: { url } } = await axiosV2.post("/founders-program");
        
        if (!url) {
            this.stripe_state = 1;
        }

        if (this.creatorInvitation === this.PAYMENT_STATES.ACCEPTED) {
            this.$router.push({ name: "web-app-profile-settings-creator-program-dashboard" });
        }
    }
}
</script>

<style lang="scss" scoped>
.creator-program-container {
    // .disabled-button {
    //     background: #2C2C2E;
    //     border-radius: 30px;
    //     border-color: transparent;
    //     cursor: not-allowed;
    // }

    padding: 0 30px;

    h3 {
        margin-bottom: 40px;
    }

    p {
        font-size: 16px;
        width: 536px;
        line-height: 20.8px;
    }

    .commissions {
        width: 570px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #1C1C1E;
        border-radius: 22px;
    }

    .commision-card {
        display: flex;
        align-items: center;
        background: #2C2C2E;
        border-radius: 4px;
        margin: 10px 0px;
        padding: 12px;

        .info-container {
            display: flex;
            justify-content: space-between;
            margin-right: 200px;
        }

        img {
            width: 40px;
            height: 39.47px;
            border-radius: 30px;
            margin: 0px 12px;
            background: red;
            border: none;
            // border-radius: 100%;
        }

        .info {
            margin: 0 10px;

            h3 {
                font-size: 16px;
                margin: 0;
            }

            display: flex;
            flex-direction: column;

        }

        .earn {
            color: #05A88F;
        }
    }
    
}
</style>